<template>
    <div id="pageTable">
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12">
                    <data-table
                        ref="tablaTipoDeduccion"
                        :tableName="'Deducciones'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        :showAdvancedFilters="false"
                        :perPage="[10,25,50,100]"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" >
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.clave}}</div></td>
                                <td><div>{{item.descripcion}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark @click="abrirModal('update', item)" small>
                                            <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Eliminar</span>
                                    </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>

        <template>
            <v-dialog v-model="dialog" persistent max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                </template>
                <v-card>
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-card-title class="justify-center card_titulo">
                            <div class="headerModal">
                                <img :src="imagen" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModal }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <div id="padre" v-show="isLoading">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pb-0">
                                        <ValidationProvider v-slot="{ errors }" name="Clave" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="Clave" 
                                                class="pa-0 ma-0" 
                                                v-model="tipoDeduccion.clave" 
                                                :error-messages="errors" 
                                                required
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                                            <v-text-field
                                                outlined 
                                                label="Descripción" 
                                                class="pa-0 ma-0" 
                                                v-model="tipoDeduccion.descripcion" 
                                                :error-messages="errors" 
                                                required
                                            >                                                
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <VuetifyDatePicker
                                            outlined
                                            v-model="tipoDeduccion.inicio_vigencia"
                                            label="Inicio de vigencia"
                                            placeholder="dd/mm/aaaa"
                                            ref="FechaInicioVigencia"
                                        />
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <VuetifyDatePicker
                                            outlined
                                            v-model="tipoDeduccion.final_vigencia"
                                            label="Fin de vigencia"
                                            placeholder="dd/mm/aaaa"
                                            ref="FechaFinalVigencia"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()" 
                                :disabled="isSaving"
                            >
                                Cancelar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="guardar()" 
                                :disabled="invalid" 
                                :loading="isSaving"
                            >
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>
    </div>
</template>

<script>
import apiTipoDeduccion from "../../api/nominas/tipoDeduccion";
import Datatable from '@/components/datatable/Datatable.vue';
import Notify from '@/plugins/notify';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';

export default {
    components: {
        VuetifyDatePicker,
        'data-table': Datatable,
    },
    data() {
        return {
            url         : "catalogo-sat/tipo-deduccion/find",
            columns     : [
                {
                    label: 'Clave',
                    name: 'clave',
                    filterable: true
                },
                {
                    label: 'Descripción',
                    name: 'descripcion',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters     : {
                activo    :true,
                paginate  :true,
                order     :"asc"
            },
            tipoDeduccion         : {
                id              : null,
                clave           : '',
                descripcion     : '',
                inicio_vigencia : '',
                final_vigencia  : ''
            },
            userData        : this.$session.get('usuario'),
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isLoading       : false,
            isSaving        : false,
            calendar_inicio : false,
            calendar_fin    : false,
            imagen          : "",
        }
    },
    methods: {
        abrirModal(accion, data = null) {
            this.accion     = accion;
            this.isLoading  = true;

            if(accion == "add") {
                this.tituloModal    = "Registro Tipo de Deducción";
                this.imagen         = "/static/modal/catalogoSATcreate.svg";
            } else {
                this.tituloModal                    = "Actualizar Tipo de Deducción";
                this.imagen                         = "/static/modal/catalogoSATupdate.svg";
                this.tipoDeduccion.id               = data.id;
                this.tipoDeduccion.clave            = data.clave;
                this.tipoDeduccion.descripcion      = data.descripcion;
                this.tipoDeduccion.inicio_vigencia  = data.inicio_vigencia;
                this.tipoDeduccion.final_vigencia   = data.final_vigencia;
                setTimeout(()=>this.$refs.FechaInicioVigencia.setDate(data.inicio_vigencia),200);
                setTimeout(()=>this.$refs.FechaFinalVigencia.setDate(data.final_vigencia),200);
            }

            this.isLoading  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.tipoDeduccion.id              = null;
            this.tipoDeduccion.clave           = '';
            this.tipoDeduccion.descripcion     = '';
            this.tipoDeduccion.inicio_vigencia = '';
            this.tipoDeduccion.final_vigencia  = '';

            if(this.$refs.FechaInicioVigencia != undefined || this.$refs.FechaInicioVigencia != null){
                this.$refs.FechaInicioVigencia.resetDate();
            }
            if(this.$refs.FechaFinalVigencia != undefined || this.$refs.FechaFinalVigencia != null){
                this.$refs.FechaFinalVigencia.resetDate();
            }

            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        guardar() {
            this.$refs.form.validate().then(success => {
            this.isSaving = true;
            if(!success) {
                this.isSaving = false;
                return;
            }

            if(this.accion === "add") {
                apiTipoDeduccion.store(this.tipoDeduccion)
                .then((response) => {
                    this.$refs.tablaTipoDeduccion.getData();
                    Notify.Success("Operación exitosa", "El registro tipo deducción se guardó satisfactoriamente.");
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    }
                });
            }
            else if(this.accion === "update") {
                apiTipoDeduccion.update(this.tipoDeduccion)
                .then((response) => {
                    this.$refs.tablaTipoDeduccion.getData();
                    Notify.Success("Operación exitosa", "El registro de tipo deducción se actualizó satisfactoriamente.");
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    }
                });
            }
            });
        },
        eliminar(data) {
            Notify.Alert(
                "¿Estás seguro de eliminar este tipo de deducción?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    apiTipoDeduccion.delete(data.id).then(response => {
                        this.$refs.tablaTipoDeduccion.getData();
                        Notify.Success("Tipo de deducción eliminado", "El tipo de deducción ha sido eliminado satisfactoriamente");
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },
    },
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
</style>

